import userRole from "../../../shared/userRole";
import itemTypes from "../../../shared/itemTypes";

export const acceptedDropByUserType = (userType, typeColumn) => {
  switch (userType) {
    case userRole.OWNER:
      return acceptedDropOwner(typeColumn);
    case userRole.STAFF:
      return acceptedDropStaff(typeColumn);
    case userRole.OPERATOR:
      return acceptedDropOperator(typeColumn);
    default:
      // manager
      return acceptedDropManager(typeColumn);
  }
}

// OWNER / CUSTOMER
const acceptedDropOwner = (typeColumn) => {
  switch (typeColumn) {
    case itemTypes.NEW_ORDER:
      // return [itemTypes.NEW_ORDER, itemTypes.ACTIVE_CARD, itemTypes.READY_TO_GO, itemTypes.QUEUE_CARD, itemTypes.DRAFT_CARD];
      return [itemTypes.ACTIVE_CARD, itemTypes.QUEUE_CARD];
    case itemTypes.IN_PROGRESS:
      return [itemTypes.IN_REVIEW];
    case itemTypes.IN_REVIEW:
      return [itemTypes.IN_REVIEW, itemTypes.APPROVED];
    case itemTypes.ON_HOLD:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW, itemTypes.ON_HOLD];
    case itemTypes.APPROVED:
      return [itemTypes.IN_REVIEW];
    default:
      //CANCELLED
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW, itemTypes.ON_HOLD];
  }
}

// STAFF / TEAM MEMBER
const acceptedDropStaff = (typeColumn) => {
  switch (typeColumn) {
    case itemTypes.NEW_ORDER:
      // return [itemTypes.NEW_ORDER, itemTypes.ACTIVE_CARD, itemTypes.READY_TO_GO, itemTypes.QUEUE_CARD, itemTypes.DRAFT_CARD];
      return [itemTypes.ACTIVE_CARD, itemTypes.QUEUE_CARD];
    case itemTypes.IN_PROGRESS:
      return [itemTypes.IN_REVIEW];
    case itemTypes.IN_REVIEW:
      return [itemTypes.IN_REVIEW, itemTypes.APPROVED];
    case itemTypes.ON_HOLD:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW, itemTypes.ON_HOLD];
    case itemTypes.APPROVED:
      return [itemTypes.IN_REVIEW];
    default:
      //CANCELLED
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW, itemTypes.ON_HOLD];
  }
}

// OPERATOR
const acceptedDropOperator = (typeColumn) => {
  switch (typeColumn) {
    case itemTypes.NEW_ORDER:
      // return [itemTypes.NEW_ORDER, itemTypes.ACTIVE_CARD, itemTypes.READY_TO_GO, itemTypes.QUEUE_CARD, itemTypes.DRAFT_CARD];
      return [];
    case itemTypes.IN_PROGRESS:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS];
    case itemTypes.IN_REVIEW:
      return [itemTypes.IN_PROGRESS];
    case itemTypes.ON_HOLD:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.ON_HOLD];
    case itemTypes.APPROVED:
      return [];
    default:
      //CANCELLED
      return [];
  }
}

// MANAGER
const acceptedDropManager = (typeColumn) => {
  switch (typeColumn) {
    case itemTypes.NEW_ORDER:
      // return [itemTypes.NEW_ORDER, itemTypes.ACTIVE_CARD, itemTypes.READY_TO_GO, itemTypes.QUEUE_CARD, itemTypes.DRAFT_CARD];
      return [];
    case itemTypes.IN_PROGRESS:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_REVIEW];
    case itemTypes.IN_REVIEW:
      return [itemTypes.IN_PROGRESS, itemTypes.APPROVED];
    case itemTypes.ON_HOLD:
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW];
    case itemTypes.APPROVED:
      return [itemTypes.IN_REVIEW];
    default:
      //CANCELLED
      return [itemTypes.ACTIVE_CARD, itemTypes.IN_PROGRESS, itemTypes.IN_REVIEW, itemTypes.ON_HOLD];
  }
}