import React from 'react';
import PropTypes from 'prop-types';

import './AppLoader.scss';

import Spinner from '../Spinner/Spinner';

function AppLoader({ branding = null, loading = false }) {
  return (loading && (
      <div className="client-app-loader"><Spinner /></div>
    ));
};

AppLoader.propType = {
  branding: PropTypes.object,
  loading: PropTypes.bool
};

export default AppLoader;