import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import feather from 'feather-icons';
import { CSSTransition } from 'react-transition-group';

import './Modal.css';

const duration = 100;

const Modal = ({ 
  children, 
  show, 
  onHideModal, 
  isForceShow = false, 
  useClose = true, 
  modalSize = "modal-xl", 
  style = null, 
  styleBackDrop = null,
  className = ''
}) => {
  const classBackDrop = ['modal-backdrop', 'fade'];
  const classesModalContainer = ['modal', 'fade', className];
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    feather.replace();
  }, [show]);

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target) && !isForceShow) {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    onHideModal(false);
  };

  return (
    <Fragment>
      <CSSTransition in={show} timeout={duration} classNames="modal-hoc" mountOnEnter unmountOnExit>
        <div className={classesModalContainer.join(' ')} style={style}>
          <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
            <div className="modal-content" ref={wrapperRef}>

              {useClose && (
                <button type="button" className="btn btn-icon btn-close" onClick={handleCloseModal}>
                  <i data-feather="x-circle"></i>
                </button>
              )}

              <div className="modal-body">
                {children}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition in={show} timeout={duration} classNames="modal-hoc-backdrop" mountOnEnter unmountOnExit>
        <div className={classBackDrop.join(' ')} style={styleBackDrop}></div>
      </CSSTransition>
    </Fragment>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  useClose: PropTypes.bool,
  isForceShow: PropTypes.bool,
  modalSize: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};

export default Modal;
