import React, { useEffect } from 'react'
import {
  frontchatChatId,
  frontchatVerificationSecret,
} from '../shared/constant'
import userRole from '../shared/userRole'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function isInternalStaff(user) {
  return (
    user &&
    user.role &&
    (user.role === userRole.CREATIVE_MANAGER ||
      user.role === userRole.MANAGER ||
      user.role === userRole.PRODUCTION_MANAGER ||
      user.role === userRole.OPERATOR)
  )
}

const useLiveChat = (user) => {
  // useEffect(() => {
  //   if (!isInternalStaff(user)) {
  // const script = document.createElement('script')
  // script.src = `//code.tidio.co/${tidioCode}.js`
  // script.async = true
  // document.body.appendChild(script)

  // return () => {
  // document.body.removeChild(script)
  // const tidioChatCode = document.getElementById('tidio-chat-code')
  // const tidioChat = document.getElementById('tidio-chat')
  // if (tidioChatCode !== null) {
  //   tidioChatCode.remove()
  // }
  // if (tidioChat !== null) {
  //   tidioChat.remove()
  // }
  // }
  //   }
  // }, [user])

  useEffect(() => {
    if (
      !isInternalStaff(user) &&
      frontchatVerificationSecret !== '' &&
      frontchatChatId !== '' &&
      process.env.NODE_ENV === 'production'
    ) {
      const userDetail = user.userDetail
      const crypto = require('crypto')
      const hmac = crypto.createHmac('sha256', frontchatVerificationSecret)
      const userHash = hmac.update(userDetail.emailAddress).digest('hex')
      const script = document.createElement('script')
      script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        window.FrontChat('init', {
          email: userDetail.emailAddress,
          name: `${userDetail.firstname} ${userDetail.lastname}`,
          userHash: userHash,
          chatId: frontchatChatId,
          useDefaultLauncher: true,
        })
      }

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [user])

  return (
    <>
      {/* {!isInternalStaff(user.user) && process.env.NODE_ENV === 'production' && (
        <IntercomProvider appId={IntercomCode} autoBoot>
          <p>Hi there, I am a child of the IntercomProvider</p>
        </IntercomProvider>
      )} */}
    </>
  )
}

useLiveChat.propTypes = {
  userDetail: PropTypes.object,
}

const mapStateToProps = ({ user }) => {
  return { userDetail: user }
}

export default withRouter(connect(mapStateToProps)(useLiveChat))
