import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';

export class RequestTokenAPI {
    constructor() {
        this.subscription = null;
    }

    subscribe(source, next, complete, error) {
        const token = localStorage.getItem("token");
        const httpConfig = {
            url: `/g/csrf/${source}`,
            method: 'get',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        let observable$ = new Observable((observer) => {
            return new AxiosSubscriber(observer, httpConfig, false);
        });

        this.subscription = observable$.subscribe({
            next: next,
            complete: complete,
            error: error
        });
    }

    unsubscribe() {
        if (this.subscription !== undefined && this.subscription !== null) {
            this.subscription.unsubscribe();
        }
    }
}