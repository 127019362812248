import React, { useState, useEffect } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { generalSettingsTourGuideService } from '../../services/tourGuideService/generalSettingsTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import clientLogin from '../../shared/clientLogin'

const initSteps = [
  {
    target: '.setting-password',
    title: 'Manage Password',
    content:
      'You can update your account password by filling out the Current Password, New Password, and Repeat New Password field and click on the Update Password button.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
  },
  {
    target: '.setting-notification-top',
    title: 'Notification Setting',
    content:
      'You will get notification related your order in the bell notification on your dashboard by default. You can also set your notification setting to be delivered by other methods.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
  },
  {
    target: '.tour-setting-deletion',
    title: 'Account deletion',
    content:
      'Click on the Delete Account button to show the Account Deletion screen.',
    disableBeacon: true,
    type: 'hover',
    isFixed: true,
    spotlightPadding: 0,
    lastStep: true,
  },
]

const GeneralSettingsTourGuide = ({ run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps, setSteps] = useState([])

  const customStyles = {
    overlay: {
      position: 'fixed',
    },
    buttonClose: {
      display: 'none',
    },
  }

  useEffect(() => {
    const clientId = localStorage.getItem('clientId')
    if (clientId === null) return false

    if (clientId === clientLogin.ADMIN) {
      setSteps([initSteps[0], initSteps[1]])
    } else {
      setSteps(initSteps)
    }
  }, [])

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('generalSettings', 'false', () => {
        userDetailService.emitReload(true)
      })
    }
  }

  useEffect(() => {
    const subscription = generalSettingsTourGuideService
      .reload()
      .subscribe((value) => {
        if (value) {
          setAutoRun(run)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [run])

  return (
    <div className="tour-guide-general-setting">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default GeneralSettingsTourGuide
