import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';

export class OrderFilterCustomerListAPI {
  constructor() {
    this.subscription = null;
  }

  subscribe(data, next, complete, error) {
    const token = localStorage.getItem("token");

    const httpConfig = {
      url: `/filters/customers`,
      method: 'get',
      params: data,
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig);
    });

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error
    });
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }
}