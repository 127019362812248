import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';
import { requestAccessToken, requestTokenSourceType, requestTokenSourceByLogin } from '../../shared/requestAccessToken';

export class OrderFilterUpdateAPI {
  constructor() {
    this.subscription = null;
  }

  subscribe(filterId, data, next, complete, error) {
    const token = localStorage.getItem("token");

    const sourceType = requestTokenSourceByLogin(requestTokenSourceType.FILTER);
    requestAccessToken(sourceType, csrfToken => {
      const httpConfig = {
        url: `/filters/${filterId}`,
        method: 'put',
        data: data,
        headers: {
          'x-csrf': csrfToken,
          'accept': 'application/json',
          'Content-Type': `application/json`,
          'Authorization': `Bearer ${token}`
        }
      };

      let observable$ = new Observable((observer) => {
        return new AxiosSubscriber(observer, httpConfig);
      });

      this.subscription = observable$.subscribe({
        next: next,
        complete: complete,
        error: error
      });
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }
}