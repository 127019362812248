import React from 'react';
import PropTypes from 'prop-types';

import './ApiLoader.scss';

import Spinner from '../Spinner/Spinner';

function ApiLoader({ branding = null, loading = false }) {
  return (loading && (
      <div className="client-api-loader">
        <Spinner />
      </div>
    ));
};

ApiLoader.propType = {
  branding: PropTypes.object,
  loading: PropTypes.bool
};

export default ApiLoader;