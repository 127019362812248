import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import tippy from 'tippy.js';

import itemTypes from '../../shared/itemTypes';
import { defaultDisplayDate } from '../../shared/dateTimeHelper';
import Avatar from '../Avatar/Avatar';
import { AbilityContext } from '../../context/abilityContext';
import { abilityAction, abilityComponent } from '../../shared/ability';
import { truncateText, capitalizeFirstLetter } from '../../shared/utility';

// TODO: refactoring with design order card id
const QuickSearchResultItem = ({ item = null }) => {
  const abilityContext = useContext(AbilityContext);

  let statusCard = "active";
  const classCard = ["card-item"];
  const navigateLinkCard = `/orders/detail/${item.id}`;
  let cssClassOrderPriority = [];


  useEffect(() => {
    tippy('.title-hover', {
      animation: 'shift-away',
      theme: 'title-card',
      onTrigger(instance, _) {
        instance.setContent(instance.reference.dataset.tippyContent);
      },
    });
  }, [item]);

  // only for operator and manager
  const companyName = (card) => {
    if (abilityContext.can && abilityContext.can(abilityAction.READ, abilityComponent.ORDER_LIST_COMPANY_NAME) && card.company) {
      return (
        <div className="d-flex flex-row align-items-end">
          <span className="font-weight-bold text-14 line-height-14 title-hover"
            data-tippy-content={card.client}>{truncateText(card.client, 12)}</span>
          <span>&nbsp;/&nbsp;</span>
          <span className="font-weight-bold text-14 line-height-14 title-hover"
            data-tippy-content={card.company.name}>{truncateText(card.company.name, 12)}</span>
          <span>&nbsp;&nbsp;</span>
          {card.packageName && (
            <span className="package-name title-hover"
              data-tippy-content={capitalizeFirstLetter(card.packageName)}>{capitalizeFirstLetter(truncateText(card.packageName, 3, ""))}</span>
          )}
        </div>
      );
    }

    return null;
  };

  switch (item.orderStateStatus) {
    case itemTypes.ACTIVE_CARD:
      classCard.push("card-item-active");
      statusCard = "Active Order";
      break;
    case itemTypes.READY_TO_GO:
      classCard.push("card-item-active");
      statusCard = "Ready To Go";
      break;
    case itemTypes.QUEUE_CARD:
      classCard.push("card-item-queue");
      statusCard = "Queue Order";
      cssClassOrderPriority.push("priority-order-warning");
      break;
    case itemTypes.DRAFT_CARD:
      classCard.push("card-item-draft");
      statusCard = "Draft Order";
      cssClassOrderPriority.push("d-none");
      break;
    case itemTypes.IN_PROGRESS:
      classCard.push("card-item-inprogress");
      statusCard = "In Progress";
      break;
    case itemTypes.IN_REVIEW:
      classCard.push("card-item-inreview");
      statusCard = "In Review";
      break;
    case itemTypes.ON_HOLD:
      classCard.push("card-item-onhold");
      statusCard = "On Hold Order";
      cssClassOrderPriority.push("d-none");
      break;
    case itemTypes.APPROVED:
      classCard.push("card-item-approved");
      statusCard = "Approved Order";
      cssClassOrderPriority.push("d-none");
      break;
    case itemTypes.CANCELLED:
      classCard.push("card-item-cancelled");
      statusCard = "Cancelled Order";
      cssClassOrderPriority.push("d-none");
      break;
    default:
      classCard.push("card-item-active");
      statusCard = "Active Order";
  }

  return (
    <li className={`quick-search-result-item ${classCard.join(' ')}`}>
      <NavLink to={navigateLinkCard} className="card-order-link">
        <div className="quick-search-result-item-wrapper card-item-container">
          <div className="title-status-card d-flex flex-row line-height-12">
            <div className="title-status d-flex flex-row align-items-center font-weight-bold flex-grow-1 line-height-12">
              <span className="dot dot-12 mr-3"></span>
              <span className="font-weight-bold text-14 line-height-14">{statusCard}</span>
            </div>
            <div>
              <span className={`priority-order ${cssClassOrderPriority.join(' ')}`}>Priority {(parseInt(item.positionOrder) + 1)}</span>
            </div>
          </div>
          {companyName(item)}
          <div className="d-flex flex-row mt-3 card-content-info">
            <div className="d-flex flex-column justify-content-end mr-3">
              {(item.orderCreator.image && (
                <Avatar src={item.orderCreator.image} user={{ firstname: item.orderCreator.name }} radius={50} />
              ))}
            </div>
            <div className="mt-3">
              <div className="line-height-12 mb-3">
              <span className="font-weight-bold text-14 title-hover" data-tippy-content={item.orderName}>{truncateText(item.orderName, 32)}</span>
              </div>
              <div className="line-height-14">
                <span className="text-10">{item.orderId}</span>
                {item.serviceName && (
                  <>
                    <span className="text-10">&nbsp;/&nbsp;</span>
                    <span className="font-weight-bold text-10">{item.serviceName}</span>
                  </>
                )}
              </div>
              <div className="line-height-12">
                <span className="text-10">{defaultDisplayDate(new Date(item.createdAt))}</span>&nbsp;/&nbsp;
                {item.dueDate && (
                  <span className="font-weight-bold text-10">{defaultDisplayDate(new Date(item.dueDate))}</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-end ml-auto">
              <div className="d-flex flex-column align-items-end justify-content-center" style={{ marginLeft: '5px' }}>
                {item.operator !== null
                  ? (
                    <Avatar src={item.operator.image} user={{ firstname: item.operator.label }} radius={30} />
                  )
                  : (<span className="operator-unassigned"></span>)}
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </li>
  )
};

export default React.memo(QuickSearchResultItem);
